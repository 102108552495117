<template>
  <div class="basket_block">
    <div class="basket_header_block">
      <div class="basket_header_container">
        <div class="basket_header_label">
          {{$t('Basket')}}
        </div>
        <div class="basket_header_basket_block">
          <div class="basket_header_basket">
            <div class="basket_header_basket_img">
              <div class="basket_header_basket_number">{{ countOfGoods }}</div>
            </div>
            <div class="basket_header_basket_text">{{$t('Basket')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="basket_main_block">
      <div class="basket_main_container">
        <div class="basket_list_goods">
          <div class="basket_coins">
            <div class="basket_coins_text">{{$t('coin_count')}}:</div>
            <div class="basket_coins_quantity">{{ activeUserCoins }}</div>
          </div>
          <div class="basket_contents">
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">

              <div class="basket_contents_item" v-for="good in basketData">
                <el-checkbox :label="good.id">{{good.id}}</el-checkbox>
                <!--              <div class="basket_contents_img"></div>-->
                <img
                  :src="backUrl + mainImage(good)"
                  class="basket_contents_img"
                  @error="setAltImg"
                  :alt="good.translations.find(({locale}) => locale === $i18n.locale).name"
                />
                <div class="basket_contents_desc">
                  <div class="basket_contents_desc_title">{{ good.translations.find(({locale}) => locale === $i18n.locale).name }}</div>
                  <div
                    class="basket_contents_desc_available"
                    :style="{
                      color: errorIds.indexOf(good.id) !== -1 ? 'red' : ''
                    }"
                  >{{ $t('Available_items') }}: {{ good.count }}</div>
                </div>
                <div class="basket_contents_quantity">
                  <button class="basket_contents_quantity_minus" @click="delGood(good.id)"
                  ></button>
                  <div class="basket_contents_quantity_number">{{ good.pivot.count }}</div>
                  <button class="basket_contents_quantity_plus" @click="addGood(good.id)" :disabled="good.pivot.count >= good.count"
                  ></button>
                </div>
                <div class="basket_contents_price">{{ good.price }}</div>
              </div>

            </el-checkbox-group>

            <div v-show="basketData.length === 0" class="basket_contents_empty">{{$t('basket_empty')}} =(</div>
          </div>
        </div>
        <div class="basket_order_container">
          <div class="basket_order_selection">
            <div class="basket_order_selection_detail">
              <div class="basket_order_selection_title">{{$t('item_sel')}}:</div>
              <div class="basket_order_selection_quantity">{{ this.basketData.length }} товара(-ов)</div>
            </div>
<!--            <label class="basket_order_selection_checkbox" for="basket_order">-->
<!--              <input type="checkbox" class="basket_order_checkbox" id="basket_order"/>-->
<!--              <span>Выбрать все товары</span>-->
<!--            </label>-->

            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              class="basket_order_selection_checkbox"
              v-show="!showModalPurchaseCompleted"
            >
              <span>{{$t('select_all')}}</span>
            </el-checkbox>

          </div>
          <div class="basket_order_total">
            <div class="basket_order_total_detail">
              <div class="basket_order_total_title">{{$t('total')}}</div>
              <div class="basket_order_total_quantity">{{ priceOfGoods }}</div>
            </div>
            <div class="basket_order_total_price">
              <div class="basket_order_total_price_title">
                <span>{{$t('products')}}, {{ countOfGoods }} {{$t('count')}}</span>
                <span>{{ priceOfGoods }}</span>
              </div>
              <div class="basket_order_total_price_quantity">
                <span>{{$t('shipping')}}</span>
                <span>{{$t('free')}}</span>
              </div>
            </div>
          </div>

          <div class="basket_order_details">
            <form-group label="Номер телефона" name="phone">
              <el-input
                v-model="detailForm.phone"
                v-mask="'+7 (###) ### ##-##'"
                autocomplete="off"
              />
            </form-group>
            <form-group label="Область" name="state_id">
              <el-select v-model="detailForm.state_id" :loading="states.loading">
                <el-option
                  v-for="item in states.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </form-group>
            <form-group label="Район" name="city_id">
              <el-select v-model="detailForm.city_id" :disabled="!detailForm.state_id" :loading="addressCities.loading">
                <el-option
                  v-for="item in addressCities.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </form-group>
            <form-group label="Населенный пункт" name="district_id">
              <el-select v-model="detailForm.district_id"  :disabled="!detailForm.city_id" :loading="districts.loading">
                <el-option
                  v-for="item in districts.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </form-group>
            <form-group label="Улица" name="street">
              <el-input v-model="detailForm.street"/>
            </form-group>
            <form-group label="Номер дома" name="house">
              <el-input v-model="detailForm.house"/>
            </form-group>
            <form-group label="Квартира" name="apartment">
              <el-input v-model="detailForm.apartment"/>
            </form-group>
          </div>
<!--          <div class="basket_order_date">-->
<!--            {{$t('date')}}:-->
<!--            <div class="basket_order_date_period">9-14июня</div>-->
<!--          </div>-->
          <div class="basket_order_offer">
            <label class="basket_order_offer_checkbox" for="basket_offer">
              <input
                type="checkbox"
                class="basket_offer_checkbox"
                id="basket_offer"
                v-model="agreementWithTerms"
              />
            </label>
            <div class="basket_order_offer_text">
             {{$t('shop_agree')}}
            </div>
          </div>
          <div class="basket_order_insufficient_funds" v-show="priceOfGoods > this.userCoins">
            {{$t('no_money')}}
          </div>
          <button
            @click="createOrder"
            :disabled="disabledBuyBtn"
            v-bind:class="[disabledBuyBtn ? disabledBtnClass : '', basicBtnClass]"
          >
            {{$t('buy_products')}}
          </button>
        </div>
      </div>
      <div v-show="showModal" class="basket_modal">
        <div class="basket_modal_back">
        </div>
        <div class="basket_modal_front">
          <div class="basket_modal_front_title">{{$t('no_money')}}</div>
          <div class="basket_modal_front_icon"></div>
          <div class="basket_modal_close_form" @click="showModal = false"></div>
        </div>
      </div>
      <div v-show="showModalPurchaseCompleted" class="basket_modal_purchase_completed">
        <div class="basket_modal_block">
          <div class="basket_modal_label">
            {{$t('buy_succes')}}
          </div>
          <div class="basket_modal_img">

          </div>
          <div class="basket_modal_my_orders">
            {{$t('to_buy')}}
            <router-link class="basket_modal_my_orders" to="/my_orders">   {{$t('My_orders')}}</router-link>
          </div>
          <div class="basket_modal_close_btn" @click="showModalPurchaseCompleted = false"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import VueMask from "v-mask";

  export default {
    name: "Basket",
    data() {
      return {
        basketItemQuantity: 8,
        deliveryNumber: 0,
        basketNumber: 10,
        basketData: [],
        backUrl: window.API_ROOT + '/images/goods/',
        userCoins: null,
        insufficientFunds: false,
        isLoading: false,
        showModal: false,
        showModalPurchaseCompleted: false,
        disabledBtnClass: 'basket_order_btn_buy_disabled',
        basicBtnClass: 'basket_order_btn_buy',
        agreementWithTerms: false,
        checkedCities: [],
        isIndeterminate: true,
        cities: this.countOfGoodsId,
        checkAll: false,
        noPhoto: require('@/assets/images/no_photo.png'),

        errorIds: [],
        showAddressInput: false,
        detailForm: {
          phone: null,
          state_id: null,
          city_id: null,
          district_id: null,
          street: null,
          house: null,
          apartment: null,
        },
        states: {
          loading: false,
          data: []
        },
        districts: {
          loading: false,
          data: []
        },
        addressCities: {
          loading: false,
          data: []
        },
      }
    },
    methods: {
      basketList() {
        if (localStorage.getItem('basket_id')) {
          let basket_id = localStorage.getItem('basket_id')
          this.isLoading = true

          this.$http.get(API_ROOT + `/api/shop/basket/goods?basket_id=${basket_id} `)
            .then((res) => {
              console.log(res)
              console.log(this.$i18n.locale)
              this.basketData = res.data.data
            })
            .catch(err => {
              console.log(err)
              Vue.toastr({
                message: 'Error',
                description: err.message,
                type: 'error'
              })
            })
            .finally(() => this.isLoading = false)
        }
      },
      addGood(goodId) {
        this.isLoading = true
        console.log(this.isLoading)
        this.$http.post(API_ROOT + `/api/shop/basket/add`, {
          "basket_id": localStorage.getItem('basket_id'),
          "good_id": goodId
        })
          .then((res) => {
            console.log(res)
            Vue.toastr({
              message: 'Success',
              description: 'Product add to cart',
              type: 'success'
            })
          })
          .then(() => {
            this.basketList()
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false
            console.log(this.isLoading)
          })
      },
      delGood(goodId) {
        this.isLoading = true
        console.log(this.isLoading)
        this.$http.delete(API_ROOT + `/api/shop/basket/remove?basket_id=${localStorage.getItem('basket_id')}&good_id=${goodId}`)
          .then((res) => {
            console.log(res)
            Vue.toastr({
              message: 'Success',
              description: 'Product delete from cart',
              type: 'success'
            })
          })
          .then(() => {
            this.basketList()
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false
            console.log(this.isLoading)
          })
      },
      createOrder() {
        let isInvalid = false;
        Object.keys(this.detailForm).filter(key => key !== 'apartment').forEach(key => {
          if (!this.detailForm[key]) isInvalid = true
        });
        if (isInvalid) {
          Vue.toastr({
            message: this.$t('error'),
            description: 'Проверьте поля формы доставки',
            type: 'error'
          });
          return;
        }

        if (this.activeUserCoins > this.priceOfGoods) {
          if (localStorage.getItem('basket_id')) {
            this.$http.post(API_ROOT + `/api/shop/order`, {
              "basket_id": localStorage.getItem('basket_id'),
              order_address: {...this.detailForm, phone: this.detailForm.phone.replace(/\D/g, "")}
            })
              .then(() => {
                this.$http.post(API_ROOT + `/api/shop/complete-order`, {
                  "basket_id": localStorage.getItem('basket_id'),
                })
                  .then(() => {
                    this.showModalPurchaseCompleted = true;
                    localStorage.removeItem('basket_id');
                    let coins = localStorage.getItem('coins')
                    let prices = this.priceOfGoods
                    localStorage.setItem('coins', coins - prices + '')
                    this.basketData = [];
                  })
                  .catch(err => {
                    console.error(err);
                  })
              })
              .catch(err => {
                this.errorIds = err.body.error.ids ?? [];
                console.log(err.body.error);
              })
          }
        } else this.showModal = true
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },
      handleCheckAllChange(val) {
        this.checkedCities = val ? this.countOfGoodsId : [];
        this.isIndeterminate = false;
      },
      setAltImg(event) {
        event.target.src = this.noPhoto
      },
      mainImage(item) {
        for (let i = 0; i < item.gallery.length; i++) {
          if (item.gallery[i].is_main === 1) {
            return item.gallery[i].image
          }
        }
      },
      getBasketAddress() {
        this.$http.get(`${API_ROOT}/api/shop/basket/address`)
          .then(res => {
            const {
              phone,
              state_id,
              city_id,
              district_id,
              street,
              house,
              apartment
            } = res.data.data;
            this.getCities(state_id);
            this.getDistricts(city_id);
            this.detailForm = {
              phone: phone || JSON.parse(localStorage.getItem('user')).phone,
              state_id,
              city_id,
              district_id,
              street,
              house,
              apartment,
            }
          })
          .catch(err => {
            console.error(err);
          });
      },
      getStates() {
        this.states.loading = true;
        this.$http.get(`${API_ROOT}/api/references/states`)
          .then(res => {
            this.states.data = res.data.data;
            this.states.loading = false;
          })
          .catch(err => {
            this.states.loading = false;
            console.error(err);
          });
      },
      getCities(stateId) {
        this.addressCities.loading = true;
        this.$http.get(`${API_ROOT}/api/references/cities/${stateId}`)
          .then(res => {
            this.addressCities.data = res.data.data;
            this.addressCities.loading = false;
          })
          .catch(err => {
            this.addressCities.loading = false;
            console.error(err);
          });
      },
      getDistricts(cityId) {
        this.districts.loading = true;
        this.$http.get(`${API_ROOT}/api/references/districts/${cityId}`)
          .then(res => {
            this.districts.data = res.data.data;
            this.districts.loading = false;
          })
          .catch(err => {
            this.districts.loading = false;
            console.error(err);
          });
      },
    },
    mounted() {
      this.basketList();
      this.getStates();
      this.getBasketAddress();
    },
    beforeCreate() {
      Vue.use(VueMask);
    },
    computed: {
      countOfGoods() {
        let count = 0;

        for (let i = 0; i < this.basketData.length; i++) {
          count += this.basketData[i].pivot.count;
        }

        return count;
      },
      priceOfGoods() {
        let price = 0;

        for (let i = 0; i < this.basketData.length; i++) {
          price += this.basketData[i].pivot.count * this.basketData[i].price;
        }

        return price;
      },
      activeUserCoins() {
        if (localStorage.getItem('coins')) {
          return this.userCoins = localStorage.getItem('coins')
        } else return this.userCoins = 0
      },
      disabledBuyBtn() {
        return !(
          this.countOfGoods > 0
          && this.activeUserCoins > this.priceOfGoods
          && this.agreementWithTerms
        )
      },
      countOfGoodsId() {
        let newArr = [];

        for (let i = 0; i < this.basketData.length; i++) {
          newArr.push(this.basketData[i].id);
        }

        return newArr;
      },
    },
    watch: {
      basketList() {
        this.countOfGoods()
      },
      'detailForm.state_id'(stateId) {
        this.getCities(stateId);
      },
      'detailForm.city_id'(cityId) {
        this.getDistricts(cityId)
      },
    },
  }
</script>

<style scoped>

</style>
